import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import "./nav.scss"

export default function SiteNavigation({
  children,
  className = "new",
  landingPageHeader,
  ...props
}) {
  //let prevScrollpos = window.pageYOffset;
  const hideNav = () => {
    if (window.pageYOffset < 200) {
      document.querySelector(".fixed-header.new").style.display = "none"
      document.querySelector(".fixed-header.new").classList.remove("scrolled")
    } else {
      document.querySelector(".fixed-header.new").style.display = "block"
      document.querySelector(".fixed-header.new").classList.add("scrolled")
    }
    // let currentScrollPos = window.pageYOffset;
    // if (prevScrollpos > currentScrollPos) {
    //     document.querySelector('.fixed-header').style.top = "0";
    // } else {
    //     document.querySelector('.fixed-header').style.top = "-50px";
    // }
    // prevScrollpos = currentScrollPos;
    // if (timer !== null) {
    //     clearTimeout(timer);
    //     document.querySelector('.fixed-header').classList.add('scrolled')
    // }
    // let timer = setTimeout(function () {
    //     document.querySelector('.fixed-header').classList.remove('scrolled')
    // }, 2500);
  }
  useEffect(() => {
    window.addEventListener("scroll", hideNav, false)
    return () => window.removeEventListener("scroll", hideNav, false)
  }, [])

  const [navOpen, setOpen] = useState(false)
  const [navVisible, setNavVisible] = useState(false)

  return (
    <>
      <header className={className}>
        <div className="container">
          <div className="nav-wrapper">
            <Link to="/" className="spiralyze-logo" title="Spiralyze">
              <svg
                width="113"
                height="24"
                viewBox="0 0 113 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.5 13.9006V10.8708L9.75 6.07361L0 10.8708V18.9503L9.75 14.1531L16.7454 17.5938V19.6137L9.75 16.173L0 20.9702V24L9.75 19.2028L19.5 24V15.9205L9.75 11.1233L2.75333 14.5661V12.5462L9.75 9.10342L19.5 13.9006Z"
                  fill="#2BA3F7"
                />
                <path
                  d="M0 4.79721V7.82702L9.75 3.02981L19.5 7.82702V4.79721L9.75 0L0 4.79721Z"
                  fill="#2BA3F7"
                />
                <path
                  d="M28.2785 15.7782C29.1719 16.2278 30.1577 16.47 31.1605 16.4859C32.3557 16.4859 32.9856 15.9979 32.9856 15.2568C32.9856 14.5505 32.4403 14.1445 31.0591 13.676C29.148 13.0017 27.8867 11.9589 27.8867 10.3099C27.8867 8.37604 29.5412 6.91199 32.2359 6.91199C33.5494 6.91199 34.4908 7.16355 35.1687 7.48335L34.5895 9.53693C33.8465 9.17783 33.0294 8.9932 32.2021 8.99745C31.0747 8.99745 30.5306 9.51887 30.5306 10.0916C30.5306 10.8147 31.1605 11.1344 32.6616 11.6905C34.6924 12.4302 35.6295 13.4729 35.6295 15.0719C35.6295 16.9739 34.1625 18.588 31.0084 18.588C29.6949 18.588 28.3983 18.2349 27.75 17.8817L28.2785 15.7782Z"
                  fill="#2BA3F7"
                />
                <path
                  d="M37.9327 7.23099C39.0952 7.05639 40.2704 6.9773 41.4462 6.99465C43.05 6.99465 44.1916 7.2978 44.9597 7.90399C45.7094 8.47534 46.1871 9.41797 46.1871 10.5289C46.1871 11.6398 45.8292 12.5977 45.1301 13.2206C44.2437 14.0284 42.9302 14.4149 41.3954 14.4149C41.098 14.4216 40.8005 14.3993 40.5076 14.3482V18.4191H37.9327V7.23099ZM40.5076 12.3753C40.7945 12.4295 41.0866 12.4518 41.3786 12.442C42.7597 12.442 43.6123 11.7468 43.6123 10.6081C43.6123 9.56541 42.8795 8.93975 41.566 8.93975C41.211 8.92736 40.8558 8.95592 40.5076 9.02451V12.3753Z"
                  fill="#2BA3F7"
                />
                <path
                  d="M50.9804 7.08044V18.4201H48.3899V7.08044H50.9804Z"
                  fill="#2BA3F7"
                />
                <path
                  d="M53.7596 7.23173C54.9055 7.06552 56.0628 6.98655 57.221 6.99528C58.9262 6.99528 60.1213 7.24838 60.9387 7.88797C61.6434 8.42604 62.0141 9.23385 62.0141 10.2766C62.0141 11.7072 60.974 12.6999 59.9846 13.0572V13.1073C60.7851 13.4271 61.229 14.1681 61.5193 15.2109C61.8774 16.5066 62.2241 17.9916 62.4397 18.424H59.7803C59.6084 18.0875 59.3194 17.1796 58.9953 15.7823C58.6711 14.3531 58.1765 13.9818 57.1025 13.9651H56.3344V18.424H53.7596V7.23173ZM56.3344 12.1104H57.3577C58.6543 12.1104 59.4209 11.4709 59.4209 10.4782C59.4209 9.45212 58.705 8.91406 57.5113 8.91406C57.117 8.89776 56.7223 8.92574 56.3344 8.9974V12.1104Z"
                  fill="#2BA3F7"
                />
                <path
                  d="M67.2335 15.5101L66.4147 18.4201H63.737L67.2335 7.08044H70.6441L74.2266 18.4201H71.4079L70.5215 15.5101H67.2335ZM70.1495 13.5915L69.4335 11.1862C69.2291 10.5119 69.0248 9.67068 68.8543 8.9978H68.8247C68.6541 9.67068 68.4836 10.5271 68.2962 11.1862L67.614 13.5915H70.1495Z"
                  fill="#2BA3F7"
                />
                <path
                  d="M76.2941 7.08044H78.8872V16.2664H83.4535V18.4201H76.2941V7.08044Z"
                  fill="#2BA3F7"
                />
                <path
                  d="M86.6419 18.4201V13.7764L83.0086 7.08044H85.9936L87.1535 9.80561C87.5114 10.6301 87.768 11.2363 88.0399 11.9759H88.0752C88.3302 11.2697 88.6037 10.6133 88.9447 9.80561L90.1046 7.08044H93.0205L89.2336 13.693V18.4201H86.6419Z"
                  fill="#2BA3F7"
                />
                <path
                  d="M94.0784 17.041L99.5481 9.26754V9.20073H94.5731V7.08044H102.913V8.56125L97.5581 16.2332V16.2999H102.999V18.4201H94.0784V17.041Z"
                  fill="#2BA3F7"
                />
                <path
                  d="M112.006 13.6249H107.778V16.3179H112.5V18.4201H105.186V7.08044H112.261V9.18267H107.774V11.5462H112.002L112.006 13.6249Z"
                  fill="#2BA3F7"
                />
              </svg>
            </Link>
            <Link to="/get-demo" className="nav-cta">
              Get a Demo
            </Link>
            <div
              className="nav-icon"
              role="button"
              tabIndex={0}
              onClick={() => setOpen(!navOpen)}
            >
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
            <ul className={navOpen ? "nav nav-list expanded" : "nav nav-list"}>
              <li className=" nav-list-item howitworks">
                <Link to="/how-it-works/" className="nav-link">
                  How it Works
                </Link>
              </li>
              <li className=" nav-list-item cutomers">
                <Link to="/clients/" className="nav-link">
                  Clients
                </Link>
              </li>
              <li className=" nav-list-item aboutus ">
                <Link to="/full-service-team/" className="nav-link">
                  Full Service Team
                </Link>
              </li>
              <li className=" nav-list-item testimonials ">
                <Link to="/testimonials/" className="nav-link">
                  Testimonials
                </Link>
              </li>
              <li className=" nav-list-item pricing ">
                <Link to="/pricing/" className="nav-link">
                  Pricing
                </Link>
              </li>
              {/* <li className=" nav-list-item">
                                <Link to="/careers" className="nav-link">Careers</Link>
                            </li> */}
              <li className=" nav-list-item">
                <Link to="/get-demo/" className="nav-link get-demo">
                  Get a Demo
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
      <header className={navVisible ? "fixed-header new" : "fixed-header new"}>
        <div className="container">
          <div className="nav-wrapper">
            <Link to="/" className="spiralyze-logo" title="Spiralyze">
              <svg
                width="105"
                height="28"
                viewBox="0 0 105 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 8.09596V5.32821L11.3267 0L22.4002 5.32821L22.4611 8.13719L11.3124 2.8655L0 8.09596Z"
                  fill="#2BA3F7"
                />
                <path
                  d="M22.4002 27.9999L11.3626 22.733L0 27.9999V25.0555L11.4558 19.8416L19.8914 23.8778V20.8556L11.3924 16.7169L0 22.0522V13.1601L11.396 7.83423L22.4002 13.1012V15.8101L11.4223 10.7127L2.44909 15.0834V17.9595L11.4259 13.8432L22.4002 18.9311V27.9999Z"
                  fill="#2BA3F7"
                />
                <path
                  d="M33.6062 17.86C34.3635 18.2409 35.1992 18.446 36.0493 18.4595C37.0624 18.4595 37.5964 18.0461 37.5964 17.4183C37.5964 16.82 37.1341 16.4761 35.9633 16.0792C34.3433 15.508 33.2741 14.6247 33.2741 13.2278C33.2741 11.5896 34.6766 10.3494 36.9609 10.3494C38.0743 10.3494 38.8723 10.5625 39.447 10.8334L38.956 12.573C38.3262 12.2688 37.6335 12.1124 36.9322 12.116C35.9765 12.116 35.5153 12.5577 35.5153 13.0429C35.5153 13.6554 36.0493 13.9262 37.3217 14.3973C39.0432 15.0239 39.8376 15.9072 39.8376 17.2617C39.8376 18.8729 38.594 20.2402 35.9203 20.2402C34.8069 20.2402 33.7078 19.9411 33.1582 19.6419L33.6062 17.86Z"
                  fill="#2BA3F7"
                />
                <path
                  d="M41.7898 10.6199C42.7752 10.472 43.7714 10.405 44.7681 10.4197C46.1277 10.4197 47.0954 10.6765 47.7465 11.19C48.382 11.674 48.787 12.4725 48.787 13.4136C48.787 14.3546 48.4836 15.1661 47.891 15.6937C47.1396 16.378 46.0261 16.7054 44.7251 16.7054C44.473 16.7111 44.2208 16.6922 43.9725 16.6489V20.0974H41.7898V10.6199ZM43.9725 14.9777C44.2157 15.0236 44.4633 15.0425 44.7108 15.0342C45.8816 15.0342 46.6043 14.4453 46.6043 13.4807C46.6043 12.5974 45.9831 12.0674 44.8697 12.0674C44.5688 12.0569 44.2677 12.0811 43.9725 12.1392V14.9777Z"
                  fill="#2BA3F7"
                />
                <path
                  d="M52.8504 10.4919V20.0978H50.6545V10.4919H52.8504Z"
                  fill="#2BA3F7"
                />
                <path
                  d="M55.2065 10.62C56.1779 10.4792 57.1589 10.4123 58.1407 10.4197C59.5862 10.4197 60.5993 10.6341 61.2922 11.1759C61.8896 11.6317 62.2038 12.316 62.2038 13.1993C62.2038 14.4112 61.3221 15.2521 60.4834 15.5548V15.5972C61.162 15.8681 61.5383 16.4958 61.7844 17.3792C62.0879 18.4768 62.3818 19.7347 62.5646 20.101H60.3102C60.1645 19.816 59.9195 19.0469 59.6448 17.8632C59.37 16.6525 58.9507 16.338 58.0403 16.3239H57.3892V20.101H55.2065V10.62ZM57.3892 14.7527H58.2566C59.3557 14.7527 60.0056 14.211 60.0056 13.3701C60.0056 12.5009 59.3987 12.0451 58.3868 12.0451C58.0526 12.0313 57.718 12.055 57.3892 12.1157V14.7527Z"
                  fill="#2BA3F7"
                />
                <path
                  d="M66.6282 17.6327L65.9341 20.0978H63.6642L66.6282 10.4919H69.5193L72.5562 20.0978H70.1668L69.4154 17.6327H66.6282ZM69.1 16.0074L68.4931 13.9699C68.3198 13.3987 68.1466 12.6861 68.0021 12.1161H67.977C67.8324 12.6861 67.6879 13.4116 67.529 13.9699L66.9507 16.0074H69.1Z"
                  fill="#2BA3F7"
                />
                <path
                  d="M74.3086 10.4919H76.5068V18.2734H80.3776V20.0978H74.3086V10.4919Z"
                  fill="#2BA3F7"
                />
                <path
                  d="M83.0806 20.0978V16.1641L80.0007 10.4919H82.5311L83.5143 12.8004C83.8177 13.4988 84.0352 14.0123 84.2657 14.6389H84.2956C84.5118 14.0406 84.7436 13.4846 85.0327 12.8004L86.0159 10.4919H88.4877L85.2776 16.0934V20.0978H83.0806Z"
                  fill="#2BA3F7"
                />
                <path
                  d="M89.385 18.9295L94.0216 12.3446V12.288H89.8043V10.4919H96.8744V11.7463L92.3347 18.2452V18.3017H96.9473V20.0978H89.385V18.9295Z"
                  fill="#2BA3F7"
                />
                <path
                  d="M104.581 16.0357H100.997V18.317H105V20.0978H98.7996V10.4919H104.797V12.2727H100.994V14.2749H104.578L104.581 16.0357Z"
                  fill="#2BA3F7"
                />
              </svg>
            </Link>
            <Link to="/get-demo" className="nav-cta">
              Get a Demo
            </Link>
            <div
              className="nav-icon"
              role="button"
              tabIndex={0}
              onClick={() => setOpen(!navOpen)}
            >
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
            <ul className={navOpen ? "nav nav-list expanded" : "nav nav-list"}>
              <li className=" nav-list-item howitworks">
                <Link to="/how-it-works/" className="nav-link">
                  How it Works
                </Link>
              </li>
              <li className=" nav-list-item cutomers">
                <Link to="/clients/" className="nav-link">
                  Clients
                </Link>
              </li>
              <li className=" nav-list-item aboutus ">
                <Link to="/full-service-team/" className="nav-link">
                  Full Service Team
                </Link>
              </li>
              <li className=" nav-list-item testimonials ">
                <Link to="/testimonials/" className="nav-link">
                  Testimonials
                </Link>
              </li>
              <li className=" nav-list-item pricing ">
                <Link to="/pricing/" className="nav-link">
                  Pricing
                </Link>
              </li>
              {/* <li className=" nav-list-item">
                                <Link to="/careers" className="nav-link">Careers</Link>
                            </li> */}
              <li className=" nav-list-item">
                <Link to="/get-demo/" className="nav-link get-demo">
                  Get a Demo
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </header>
    </>
  )
}
