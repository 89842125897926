import React from "react"
import "./footerv2.scss"
import Container from "../../microcomponents/container/container"
import {
  ChevronIcon,
  LinkedInIcon,
  SPZLOGOWHITE,
} from "../../microcomponents/icons"
import { Link } from "gatsby"
export default function FooterV2({ version = "v1" }) {
  const V1Links = () => {
    return (
      <div className="links">
        <ul>
          <li
            onClick={e => {
              e.target.closest("ul").classList.toggle("toggleopen")
            }}
          >
            <h3>
              <span>Navigation</span>{" "}
              <span>
                <ChevronIcon />{" "}
              </span>{" "}
            </h3>
          </li>
          <li>
            <Link to="/how-it-works/">How it Works</Link>
          </li>
          <li>
            <Link to="/clients/">Clients</Link>
          </li>
          <li>
            <Link to="/full-service-team/">Full Service Team</Link>
          </li>
          <li>
            <Link to="/testimonials/">Testimonials</Link>
          </li>
          <li>
            <Link to="/pricing/">Pricing</Link>
          </li>
        </ul>

        <ul>
          <li
            onClick={e => {
              e.target.closest("ul").classList.toggle("toggleopen")
            }}
          >
            <h3>
              <span>Resources</span>
              <span>
                <ChevronIcon />{" "}
              </span>{" "}
            </h3>
          </li>
          <li>
            <Link to="/terms/">Terms of Service</Link>
          </li>
          <li>
            <Link to="/privacy-policy/">Privacy Policy</Link>
          </li>
        </ul>

        <ul className="toggleopen">
          <li
            onClick={e => {
              e.target.closest("ul").classList.toggle("toggleopen")
            }}
          >
            <h3>
              <span>Contacts</span>
              <span>
                <ChevronIcon />{" "}
              </span>{" "}
            </h3>
          </li>
          <li>
            <a href="mailto:contact@spiralyze.com">contact@spiralyze.com</a>
          </li>
          <li>
            <a href="tel:888.677.4725">888.677.4725</a>
          </li>
        </ul>
      </div>
    )
  }

  const V2Links = () => {
    return (
      <div className="links">
        <ul>
          {/* <li
            onClick={e => {
              e.target.closest("ul").classList.toggle("toggleopen")
            }}
          >
            <h1>
              <span>Navigation</span>{" "}
              <span>
                <ChevronIcon />{" "}
              </span>{" "}
            </h1>
          </li> */}
          <li>
            <Link to="/how-it-works/">How it Works</Link>
          </li>
          <li>
            <Link to="/clients/">Clients</Link>
          </li>
          <li>
            <Link to="/full-service-team/">Full Service Team</Link>
          </li>
        </ul>
        <ul>
          {/* <li
            onClick={e => {
              e.target.closest("ul").classList.toggle("toggleopen")
            }}
          >
            <h1>
              <span>Navigation</span>{" "}
              <span>
                <ChevronIcon />{" "}
              </span>{" "}
            </h1>
          </li> */}
          <li>
            <Link to="/testimonials/">Testimonials</Link>
          </li>
          <li>
            <Link to="/pricing/">Pricing</Link>
          </li>
          <li>
            <Link to="/about-us/">About</Link>
          </li>
        </ul>

        <ul>
          <li
          // onClick={e => {
          //   e.target.closest("ul").classList.toggle("toggleopen")
          // }}
          >
            <div className="h3">
              <span>Get in Touch</span>
              <span>
                <ChevronIcon />{" "}
              </span>{" "}
            </div>
          </li>
          <li>
            <a href="mailto:contact@spiralyze.com">contact@spiralyze.com</a>
          </li>
          <li>
            <a href="tel:888.677.4725">888.677.4725</a>
          </li>
        </ul>

        <ul>
          <li
          // onClick={e => {
          //   e.target.closest("ul").classList.toggle("toggleopen")
          // }}
          >
            <div className="h3">
              <span>Atlanta</span>
              <span>
                <ChevronIcon />{" "}
              </span>{" "}
            </div>
          </li>
          <li>
            <p>
              1718 Peachtree St. #1080
              <br />
              Atlanta, GA 30309
            </p>
          </li>
        </ul>
      </div>
    )
  }

  const V1Credits = () => {
    return (
      <React.Fragment>
        <div className="f-credits">
          <span>&copy;</span>
          <span>
            Spiralyze LLC {new Date().getFullYear()}. All Rights Reserved
          </span>
        </div>
        <div className="f-social">
          <ul>
            <li>
              <a
                href="https://www.linkedin.com/company/spiralyze"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedInIcon />
              </a>
            </li>
          </ul>
        </div>
      </React.Fragment>
    )
  }
  const V2Credits = () => {
    return (
      <React.Fragment>
        <div className="f-credits">
          <i
            style={{
              display: "none",
            }}
          >
            {Math.floor(Math.random() * (2 - 0) + 0)}
          </i>
          <span>&copy;</span>
          <span>
            Spiralyze LLC {new Date().getFullYear()}. All Rights Reserved.
          </span>
          <ul>
            <li>
              <Link to="/terms/">Terms of Service</Link>
            </li>
            <li>
              <Link to="/privacy-policy/">Privacy Policy</Link>
            </li>
          </ul>
        </div>
        <div className="f-social">
          <ul>
            <li>
              <a
                href="https://www.linkedin.com/company/spiralyze"
                target="_blank"
                rel="noreferrer"
              >
                <LinkedInIcon />
              </a>
            </li>
          </ul>
        </div>
      </React.Fragment>
    )
  }

  return (
    <div>
      <Container className={`new-footer-wrapper ${version}`}>
        <div className="footer-logo-and-links">
          <div className="logo-address">
            <Link to="/">
              <SPZLOGOWHITE />
            </Link>
            <p>
              1718 Peachtree St. #1080
              <br />
              Atlanta, GA 30309
            </p>
          </div>
          {version === "v1" ? <V1Links /> : <V2Links />}
        </div>
        <div className="footer-credits-wrapper">
          {version === "v1" ? <V1Credits /> : <V2Credits />}
        </div>
      </Container>
    </div>
  )
}
